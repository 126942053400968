@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Bogart";
    font-weight: 600;
    src: url(fonts/bogart-semibold.woff2) format("woff2"),
      url(fonts/bogart-semibold.woff) format("woff");
    font-display: swap;
  }
  @font-face {
    font-family: "Houschka Pro";
    font-weight: 500;
    src: url(fonts/HouschkaPro-Medium.woff2) format("woff2"),
      url(fonts/HouschkaPro-Medium.woff) format("woff");
    font-display: swap;
  }
}
